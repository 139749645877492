import React, { useRef } from 'react';

//assets
import ppbs from '../../../../assets/projects/ppbs.png';
import owvix from '../../../../assets/projects/owvix.png';
import simonev from '../../../../assets/projects/simonev.png';
import bni from '../../../../assets/projects/bni.png';
import avana from '../../../../assets/projects/avanadashboard.png';
import docheck from '../../../../assets/projects/dochecklanding.png';
import './style.scss';

//component
import Item from './Item';
import Title from '../../../Element/Title';
import EventScrollTracker from '../../../Element/EventScrollTracker';

const works = [
  {
    image: avana,
    title: 'AVANA',
    link: '/avana',
  },
  {
    image: docheck,
    title: 'Docheck',
    link: '/docheck',
  },
  {
    image: ppbs,
    title: 'Website Peminjaman Ruangan PPBS',
    link: '/peminjaman-ppbs',
  },
  {
    image: owvix,
    title: 'Landing Page Owvix',
    link: '/owvix',
  },
  {
    image: simonev,
    title: 'Website SIMONEV KEMENKO PMK',
    link: '/simonev-kemenko',
  },
  {
    image: bni,
    title: 'BNI x Traveloka and Bluebird',
    link: '/bni',
  },
];

const WorksSection = () => {
  const target = useRef(null);
  return (
    <EventScrollTracker targetRef={target} action={'Scroll To Work Section'}>
      <div className="works-section" ref={target}>
        <div className="left-section">
          <div className="title">
            <Title hidden={'-20vh'}>Projects</Title>
            <div className="decoration-1">
              <Title hidden={'-15vh'}>and Works</Title>
            </div>
          </div>
        </div>
        <div className="right-section">
          {works.map((item, index) => {
            return (
              <Item
                image={item.image}
                title={item.title}
                key={index}
                index={index}
                link={item.link}
              />
            );
          })}
        </div>
      </div>
    </EventScrollTracker>
  );
};

export default WorksSection;
